<template>
  <article class="our-team" :class="{ mobile: isMobile }">
    <Hero heading="Meet the Electrify America team" subheading="Get to know our people." />
    <div v-for="(array, index) in ourTeamArray" :key="array.name">
      <div class="l-one" :class="{ 'l-one--has-bg': index % 2 == 0 }" data-aos="fade-up">
        <div class="l-one__col">
          <h2 class="p">{{ array.position }}</h2>
          <h3 class="headline2">{{ array.name }}</h3>
          <p>{{ array.description }}</p>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import Hero from '@/components/Hero/Hero.vue';

export default {
  name: 'our-team',
  metaInfo: {
    title: 'Meet the Electrify America team | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Electrify America’s leadership takes our commitment to investing in the U.S. electric vehicle public charging infrastructure seriously. Get to know our team.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/our-team/' }],
  },
  components: { Hero },
  data() {
    return {
      site: process.env.VUE_APP_SITE,
      ourTeamJson: require('@/assets/json/OurTeam.json'),
    };
  },
  computed: {
    getLang() {
      return this.$store.state.lang;
    },
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    ourTeamArray() {
      return this.ourTeamJson.language[0].enus;
    },
  },
};
</script>

<style scoped lang="scss">
.our-team {
  .l-one__col {
    padding-top: 60px;
    padding-bottom: 40px;
    .p {
      font-size: 1rem !important;
      margin-bottom: 0px;
    }

    h2 {
      font-size: 40px;
      line-height: 1.2;
      margin: -20px 0 20px !important;
    }
  }
}

::v-deep .hero__subheading {
  padding-bottom: 50px;
}
</style>
